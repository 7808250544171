import { GET, POST,  DELETE, PUT } from '@/config/fetch'

// 获取月计划列表
export const getMonthPlanList = (params: any) =>
  GET('/front/dailylog/v1/monthPlan', params)
// 月度计划详情
export const getMonthPlanDetail = (params: any) =>
  GET(`/front/dailylog/v1/monthPlan/${params}`)
 // 月度计划创建
 export const addMonthPlan = (params: any) =>
 POST(`/front/dailylog/v1/monthPlan`,params)
// 月度计划修改
export const editMonthPlan = (id:string,params: any) =>
PUT(`/front/dailylog/v1/monthPlan/${id}`,params)
//月度计划导出
export const exportMonthPlan = (params: any) =>
POST(`/front/dailylog/v1/monthPlan/exportMonthPlanExcel?planYear=${params.planYear}&planMonth=${params.planMonth}`,{fileName: '月度计划'},{responseType: 'blob'})
// 月度计划指定状态
export const getMonthPlanStatus = (params: any) =>
GET(`/front/dailylog/v1/monthPlan/getMonthPlanStatus`,params)
