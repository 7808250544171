


























































































































































































import MyTinymce from '@/components/Editor/index.vue'
import { Component, Vue } from 'vue-property-decorator'
import { getWeekPlanList } from '@/api/week'
import Share from '@/components/Share/index.vue'
import {
  getMonthPlanList,
  addMonthPlan,
  getMonthPlanDetail,
  editMonthPlan,
  exportMonthPlan,
  getMonthPlanStatus,
} from '@/api/month'
import axios from 'axios'
import moment from 'moment'
import WeekInfo from './weekInfo/index.vue'
import { create } from 'lodash-es'
import Commit from '@/components/comment/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
moment.locale('zh-cn', {
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY-MM-DD',
    LL: 'YYYY年MM月DD日',
    LLL: 'YYYY年MM月DD日Ah点mm分',
    LLLL: 'YYYY年MM月DD日ddddAh点mm分',
    l: 'YYYY-M-D',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日dddd HH:mm',
  },
})
@Component({
  components: {
    MyTinymce,
    Commit,
    ThumbsUp,
    Share,
  },
})
export default class Month extends Vue {
  private content: string = ''
  private thisYear: string = new Date().getFullYear().toString()
  private dataYear: string = new Date().getFullYear().toString()
  private currentMonth: string = (new Date().getMonth() + 1).toString()
  private yearShowOne: boolean = false
  private currentPlanIndex: number = 0
  private submitLoading: boolean = false
  private isExitHover: boolean = false
  currentPlanId: string = ''
  currentStatus: string = 'done'
  private currentHoverIndex: Number = -1
  private monthList: any = [
    {
      planMonth: '1',
      planCount: '12',
    },
    {
      planMonth: '2',
      planCount: '12',
    },
    {
      planMonth: '3',
      planCount: '0',
    },
    {
      planMonth: '4',
      planCount: '0',
    },
    {
      planMonth: '5',
      planCount: '12',
    },
    {
      planMonth: '6',
      planCount: '12',
    },
    {
      planMonth: '7',
      planCount: '0',
    },
    {
      planMonth: '8',
      planCount: '12',
    },
    {
      planMonth: '9',
      planCount: '0',
    },
    {
      planMonth: '10',
      planCount: '0',
    },
    {
      planMonth: '11',
      planCount: '0',
    },
    {
      planMonth: '12',
      planCount: '0',
    },
  ]
  fileList: Object[] = [
    {
      name: '1.jpg',
    },
  ]
  private formData = {
    title: '',
    content: '',
    planYear: new Date().getFullYear().toString(),
    planMonth: (new Date().getMonth() + 1).toString(),
    businessAttachmentList: [],
    shareList: [],
  }
  private oldForm = {
    title: '',
    content: '',
    businessAttachmentList: [],
  }
  private planData: any = []
  private async changeData(type: string) {
    var that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentMonth = type
          that.formData.planMonth = type
          that.firstGetPlanList()
        },
      })
    } else {
      this.currentMonth = type
      this.formData.planMonth = type
      this.firstGetPlanList()
    }
  }
  private changhePlanItem(index: number, id: string) {
    var that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentStatus = 'done'
          that.currentPlanIndex = index
          that.currentPlanId = id
          if (that.currentPlanId) {
            that.getMonthPlanDetail(that.currentPlanId)
          } else {
            that.formData = {
              title: '',
              content: '',
              planYear: that.thisYear,
              planMonth: that.currentMonth,
              businessAttachmentList: [],
              shareList: [],
            }
            that.oldForm.title = that.formData.title
            that.oldForm.content = that.formData.content
            that.oldForm.businessAttachmentList =
              that.formData.businessAttachmentList
          }
        },
      })
    } else {
      this.currentStatus = 'done'
      this.currentPlanIndex = index
      this.currentPlanId = id
      if (this.currentPlanId) {
        this.getMonthPlanDetail(this.currentPlanId)
      } else {
        this.formData = {
          title: '',
          content: '',
          planYear: this.thisYear,
          planMonth: this.currentMonth,
          businessAttachmentList: [],
          shareList: [],
        }
        this.oldForm.title = this.formData.title
        this.oldForm.content = this.formData.content
        this.oldForm.businessAttachmentList =
          this.formData.businessAttachmentList
      }
    }
  }
  //编辑样式
  isEditBtn(index: any) {
    this.isExitHover = true
    this.currentHoverIndex = index
  }
  isNoEditBtn() {
    this.isExitHover = true
    this.currentHoverIndex = -1
  }
  //判断有没有编辑
  isChange() {
    // 只要一个有值改变并且没有点击提交，拦截
    return (
      (this.oldForm.title !== this.formData.title ||
        this.oldForm.content !== this.formData.content ||
        JSON.stringify(this.oldForm.businessAttachmentList) !==
          JSON.stringify(this.formData.businessAttachmentList)) &&
      this.currentStatus === 'edit'
    )
  }
  //给分享人赋值
  addShareList(e: any) {
    this.formData.shareList = e
  }
  //导出月计划
  private async handleExport() {
    await exportMonthPlan({
      planYear: this.thisYear,
      planMonth: this.currentMonth,
    })
  }
  //获取当前年份的计划状态
  async getMonthStatus(planYear: string) {
    var params = {
      planYear,
    }
    const [err, data] = await getMonthPlanStatus(params)
    this.monthList = data
  }

  //新建一个空项
  private createMonthPlan() {
    this.planData.unshift({
      title: '新建计划',
      updateTime: '',
    })
    this.currentStatus = 'edit'
    this.currentPlanIndex = 0
    this.currentPlanId = ''
    this.formData = {
      title: '',
      content: '',
      planYear: this.thisYear,
      planMonth: this.currentMonth,
      businessAttachmentList: [],
      shareList: [],
    }
    this.oldForm.title = this.formData.title
    this.oldForm.content = this.formData.content
    this.oldForm.businessAttachmentList = this.formData.businessAttachmentList
  }
  //查询今年列表
  selectCurrentYearList() {
    var that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.thisYear = new Date().getFullYear().toString()
          that.dataYear = that.thisYear
          that.firstGetPlanList()
          that.currentStatus = 'done'
        },
      })
    } else {
      this.thisYear = new Date().getFullYear().toString()
      that.dataYear = that.thisYear
      this.firstGetPlanList()
      this.currentStatus = 'done'
    }
  }
  //首次进入与点击月份切换时操作
  private async firstGetPlanList() {
    await this.getMonthStatus(this.thisYear)
    await this.getMonthPlanListByMonth(this.thisYear, this.currentMonth)
    if (this.planData && this.planData.length > 0) {
      this.currentPlanIndex = 0
      this.currentStatus = 'done'
      await this.getMonthPlanDetail(this.planData[0].id)
    } else {
      this.createMonthPlan()
    }
  }
  //获取当前月计划列表
  private async getMonthPlanListByMonth(planYear: any, planMonth: any) {
    var params = {
      planYear,
      planMonth,
    }

    const [err, data] = await getMonthPlanList(params)
    if (!err) {
      this.planData = data
      if (this.planData && this.planData.length > 0) {
        this.currentPlanId = this.planData[0].id
      }
    }
  }
  //编辑计划
  editPlan(index: number, id: string) {
    //表单状态设置为编辑
    this.currentStatus = 'edit'
    var that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentPlanIndex = index
          that.currentPlanId = id
          if (that.currentPlanId) {
            that.getMonthPlanDetail(that.currentPlanId)
          } else {
            that.formData = {
              title: '',
              content: '',
              planYear: that.thisYear,
              planMonth: that.currentMonth,
              businessAttachmentList: [],
              shareList: [],
            }
            that.oldForm.title = that.formData.title
            that.oldForm.content = that.formData.content
            that.oldForm.businessAttachmentList =
              that.formData.businessAttachmentList
          }
        },
      })
    } else {
      this.currentPlanIndex = index
      this.currentPlanId = id
      if (this.currentPlanId) {
        this.getMonthPlanDetail(this.currentPlanId)
      } else {
        this.formData = {
          title: '',
          content: '',
          planYear: this.thisYear,
          planMonth: this.currentMonth,
          businessAttachmentList: [],
          shareList: [],
        }
        this.oldForm.title = this.formData.title
        this.oldForm.content = this.formData.content
        this.oldForm.businessAttachmentList =
          this.formData.businessAttachmentList
      }
    }
  }
  //查询计划详情
  async getMonthPlanDetail(id: string) {
    const [err, data] = await getMonthPlanDetail(id)
    if (!err) {
      this.formData = data
      if (data.businessAttachmentList === null) {
        this.formData.businessAttachmentList = []
      }
      this.oldForm.title = this.formData.title
      this.oldForm.content = this.formData.content
      this.oldForm.businessAttachmentList = this.formData.businessAttachmentList
    }
  }
  //新建月计划
  async submitMonthPlan() {
    this.submitLoading = true
    if (!this.currentPlanId) {
      const [err, data] = await addMonthPlan(this.formData)
      if (!err) {
        this.$message.success('添加成功')
        this.submitLoading = false
        this.currentStatus = 'done'
        //修改或者添加成功后默认第一条展示
        await this.getMonthPlanListByMonth(this.thisYear, this.currentMonth)
        this.getMonthPlanDetail(this.currentPlanId)
      } else {
        this.submitLoading = false
      }
    } else {
      const [err, data] = await editMonthPlan(this.currentPlanId, this.formData)
      if (!err) {
        this.$message.success('编辑成功')
        this.submitLoading = false
        this.currentStatus = 'done'
        this.getMonthPlanListByMonth(this.thisYear, this.currentMonth)
        this.getMonthPlanDetail(this.currentPlanId)
      } else {
        this.submitLoading = false
      }
    }
  }
  // 弹出日历和关闭日历的回调
  private openChangeOne(status: any) {
    if (status) {
      this.yearShowOne = true
    } else {
      this.yearShowOne = false
    }
  }
  // 得到年份选择器的值
  private panelChangeOne(value: any) {
    var that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentStatus = 'done'
          that.yearShowOne = false
          that.dataYear = moment(value).format('YYYY')
          that.$nextTick(async () => {
            //默认查询选择年份从1月份开始
            await that.getMonthPlanListByMonth(that.dataYear, '1')
            that.thisYear = that.dataYear
            that.currentMonth = '1'
            that.firstGetPlanList()
          })
        },
      })
    } else {
      this.currentStatus = 'done'
      this.yearShowOne = false
      this.dataYear = moment(value).format('YYYY')
      this.$nextTick(async () => {
        //默认查询选择年份从1月份开始
        await this.getMonthPlanListByMonth(this.dataYear, '1')
        this.thisYear = this.dataYear
        this.currentMonth = '1'
        this.firstGetPlanList()
      })
    }
  }
  mounted() {
    //一进入查询当前年份当前月份，列表默认打开第一个
    this.firstGetPlanList()
  }
  get getRole() {
    return this.$store.state.user.userInfo.userType
  }
  get getApiToken() {
    return this.$store.state.user.userInfo.apiToken
  }
}
